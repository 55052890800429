
































































































import { Component, Vue } from 'vue-property-decorator';
import ContactPanel from '@/components/ContactPanel.vue';
import ServiceCard from '@/components/ServiceCard.vue';
import { AllianceServiceItem } from '@/models/AllianceServiceItem';
import { AllianceServicesRepository } from '@/repositories/AllianceServicesRepository';

@Component({
  components: {
    ContactPanel,
    ServiceCard
  }
})
export default class P0027 extends Vue {
  partners: AllianceServiceItem[] = [];

  created() {
    this.loadData();
  }

  async loadData() {
    const results = [];
    results.push(this.fetchPartners());
    await Promise.all(results);
  }

  async fetchPartners() {
    this.partners = await this.allianceServicesRepository.getServices();
  }

  get allianceServicesRepository() {
    return new AllianceServicesRepository();
  }
}
